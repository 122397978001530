import SEOComponent from '@/components/Misc/SEO'
import Footer from '@/components/Footer/Footer'
import PageSection from '../src/components/PageSection'
import { HOME_PAGE_NAME_PROPERTY, HOME_PAGE_VIEW_ANALYTICS_EVENT_NAME, useAmplitude } from '@/utils/hooks/useAmplitude'
import { useEffect } from 'react'
import { getHomepageApi } from '@/utils/api'
import { getPageQuery } from 'apollo/generated/wrapper/wrapper.query.types'
import Header from '@/components/Header/HeaderCommon'
import { CmsPageSlugObjType, getAllPagesSlugs } from '@/utils/cmsUtils'
import { GetServerSidePropsContext } from 'next'
import { windowExists } from '@/utils/windowUtils'

export type SinglePageType = getPageQuery['allMkMarketingPage'][0]
export type SinglePageTypeSEO = getPageQuery['allMkMarketingPage'][0]['mk_seo']

type PageBuilderProps = {
  page: SinglePageType
  cmsSlugObjs: CmsPageSlugObjType[]
}

const Index = ({ page, cmsSlugObjs }: PageBuilderProps) => {
  const { mk_pageBuilder, mk_header, mk_footer, mk_seo } = page
  const { setCmsSlugSet, logAmplitudeEvent, setPage } = useAmplitude()
  const cmsSlugSet = new Set(cmsSlugObjs.map((slug) => slug?.mk_slug?.current))

  useEffect(() => {
    if (window.location.pathname === '/') {
      // Send page view event to Amplitude
      // Will send event twice on localhost because of reactStrictMode config in next.config.js
      logAmplitudeEvent(
        HOME_PAGE_VIEW_ANALYTICS_EVENT_NAME,
        null,
        { 'Page Name': HOME_PAGE_NAME_PROPERTY },
        { isPageViewEvent: true },
      )

      // Set state for use in interaction events
      setPage(HOME_PAGE_NAME_PROPERTY)
      setCmsSlugSet(cmsSlugSet)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowExists() && window.location.href])

  const pageDetails = mk_pageBuilder?.map((pageItem, index) => <PageSection details={pageItem} key={index} />)

  return (
    <div>
      <SEOComponent seoData={{ mk_seo, slug: '' }} />
      <Header headerToDisplay={mk_header ?? 'None'} />

      {pageDetails}
      {mk_footer && <Footer />}
    </div>
  )
}

export const getServerSideProps = async ({ preview: isPreview, res }: GetServerSidePropsContext) => {
  try {
    let pageQueryResponse = await getHomepageApi(!!isPreview)
    let page = pageQueryResponse?.allMkHomePage[0]

    // If preview page no longer exists, get the actual page
    if (isPreview && !page) {
      pageQueryResponse = await getHomepageApi(false)
      page = pageQueryResponse.allMkHomePage[0]
    }

    let cmsSlugObjs = [] as CmsPageSlugObjType[]

    try {
      cmsSlugObjs = (await getAllPagesSlugs()) ?? []
    } catch (err) {
      //  Fallback: Analytics will flush before all navigation. May cause slight delay after clicking links before nav.
      console.warn(`\nError in getAllPagesSlugs(). Navigation between pages may be slowed.\n`, err)
    }

    if (!page) {
      res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=10')
      return {
        notFound: true,
      }
    }

    res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=30')
    return { props: { page, cmsSlugObjs } }
  } catch (err) {
    // Fallback: Will show 404 until error is resolved
    console.error(`\nError on Homepage | Path: /\n`, err)

    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=10')
    return {
      notFound: true,
    }
  }
}

export default Index
